import moment from 'moment';

export const prepareInvoiceConsumptionForDB = (consumptionBase, data, startDate, endDate) => {
  // Apportion waste consumption into months (if start and end date cover multiple months)

  const consumptionData = [];

  // const consumptionBase = {
  //   source: 'invoice',
  //   accountId: this.account._id,
  //   entityId: this.account.entityId,
  //   companyId: this.account.companyId,
  //   invoiceId: createdInv._id
  // };

  // const globalStartDate = moment(createdInv.values.startDate);
  // const globalEndDate = moment(createdInv.values.endDate);

  const consumptionValues = Object.entries(data)
    .filter(([, value]) => value.value !== null && value.value !== '')
    .map(([category, value]) => ({
      startTimestamp: moment(startDate).endOf('day'),
      endTimestamp: moment(endDate).endOf('day'),
      timestamp: moment(startDate).endOf('day'),
      value: parseFloat(value.value),
      unit: value.unit,
      category: category,
      readType: 'A',
      ...consumptionBase
    }));

  consumptionData.push(...consumptionValues);

  return consumptionData;
};

export const granularityFormatMap = {
  halfhourly: { format: 'Do MMM YYYY HH:mm', minTick: 1000 * 60, label: 'Half Hourly' },
  hourly: { format: 'Do MMM YYYY HH:mm', minTick: 1000 * 60 * 60, label: 'Hourly' },
  daily: { format: 'Do MMM YYYY', minTick: 1000 * 60 * 60 * 24, label: 'Daily' },
  weekly: { format: 'Do MMM YYYY', minTick: 1000 * 60 * 60 * 24, label: 'Weekly' },
  monthly: { format: 'MMM YYYY', minTick: 1000 * 60 * 60 * 24 * 27, label: 'Monthly' },
  quarterly: { format: '[Q]Q YYYY', minTick: 1000 * 60 * 60 * 24 * 55, label: 'Quarterly' },
  yearly: { format: 'YYYY', minTick: 1000 * 60 * 60 * 24 * 365, label: 'Yearly' }
};

export const chartColours = [
  '#65C198',
  '#1F303D',
  '#F25F5C',
  '#FFE066',
  '#1B98E0',
  '#91cc75',
  '#c91466',
  '#fac858',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#f6c85f',
  '#3e97d1',
  '#6f9eaf',
  '#f6b26b',
  '#b6a2de',
  '#affffa',
  '#aaaaaa',
  '#444444',
  '#ffff00',
  '#f4b7da',
  '#4da9c9',
  '#fda222',
  '#4da555'
];

export const chartConsumptionOptions = {
  color: chartColours,
  dataZoom: [
    {
      type: 'inside'
    },
    {
      type: 'slider',
      dataBackground: {
        lineStyle: {
          color: '#65C198',
          opacity: 0.2
        },
        areaStyle: {
          color: '#65C198',
          opacity: 0.2
        }
      },
      selectedDataBackground: {
        lineStyle: {
          color: '#65C198',
          opacity: 0.5
        },
        areaStyle: {
          color: '#65C198',
          opacity: 0.5
        }
      },
      fillerColor: 'rgba(101, 193, 152, 0.2)',
      borderColor: '#65C198',
      moveHandleStyle: {
        color: 'rgba(101, 193, 152, 0.7)'
      }
    }
  ],
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      restore: {},
      saveAsImage: {}
    }
  },
  animationEasing: 'elasticOut'
};
